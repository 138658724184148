<template>
  <div class="reservation">
    <div class="reservation-head" :class="calssStatus()">
      <div class="reservation-head-day">
        <div class="reservation-head-day-time">
          <div class="reservation-head-day-time-left">
            <div
              class="reservation-head-day-time-search"
              style="padding:0;justify-content: flex-start;"
            >
              <el-button
                type="text"
                icon="el-icon-bell"
                @click="dialogVisible = !dialogVisible"
              ></el-button>
            </div>

            <div class="reservation-head-day-time-picker-box">
              <span @click="formatDate(0, getDate())"
                ><i class="el-icon-arrow-left"></i
              ></span>
              <span v-if="!dateStatus" @click="focuss"
                >{{ getDate() }} {{ getDate(1) }}</span
              >
              <span v-if="dateStatus" @click="focuss">{{ dateStatus }}</span>
              <el-date-picker
                class="reservation-head-day-time-picker"
                v-model="value"
                ref="saveDateInput"
                type="date"
                @change="change"
                placeholder="选择日期"
              >
              </el-date-picker>
              <span @click="formatDate(1, getDate())"
                ><i class="el-icon-arrow-right"></i
              ></span>
            </div>
            <div class="reservation-head-day-time-search">
              <el-button
                type="text"
                icon="el-icon-search"
                @click="searchs"
              ></el-button>
              <el-button
                type="text"
                icon="el-icon-plus"
                @click="centerDialogVisible = true"
              ></el-button>
            </div>
          </div>
        </div>

        <div class="reservation-head-day-time-tips">
          <div>员工预约</div>
        </div>
      </div>
      <div class="reservation-head-time">
        <span v-for="(item, index) in time" :key="index">{{ item }}</span>
      </div>
    </div>
    <div class="reservation-cont">
      <div class="reservation-cont-left">
        <div
          class="reservation-cont-left-item"
          v-for="(item, index) in user"
          :key="index"
        >
          <div class="reservation-cont-left-item-img">
            <img :src="item.img" :alt="item.name" v-if="item.img" />
            <img v-else src="../../assets/none.png" :alt="item.name" />
          </div>
          <div class="reservation-cont-left-item-info">
            <div class="reservation-cont-left-item-info-name">
              {{ item.name }}
            </div>
            <div class="reservation-cont-left-item-info-number">
              <span v-if="item.dep">{{ item.dep.name }}</span
              >({{ item.number }})
            </div>
          </div>
        </div>
      </div>
      <div class="reservation-cont-right">
        <div
          class="reservation-cont-right-line"
          :style="{ left: lineX + 'px' }"
        >
          <!-- 我是时间线 -->
        </div>
        <div
          class="reservation-cont-right-c"
          v-for="(item, index) in time"
          :key="index"
        >
          <div class="reservation-cont-right-c-box">
            <div
              class="reservation-cont-right-c-box-item"
              v-for="(items, indexs) in user"
              :key="indexs"
            >
              <div
                class="reservation-cont-right-c-box-item-list"
                v-if="!items.data.length"
              >
                <div
                  style="width:100%;height:100%;"
                  :id="'tb-' + index + '-' + indexs"
                  @click="ressrv(index, indexs, 0, $event)"
                ></div>
              </div>

              <div
                class="reservation-cont-right-c-box-item-list"
                v-for="(list, k) in items.data"
                :key="k"
                :style="{ zIndex: shows(list.start, item, list.end) ? 1 : 0 }"
              >
                <div
                  v-if="shows(list.start, item, list.end) == 0"
                  style="width:100%;height:100%;"
                  :id="'tb-' + index + '-' + indexs"
                  @click="ressrv(index, indexs, k, $event)"
                ></div>
                <!-- <div
                  v-if="shows(list.start, item, list.end) == 4"
                  style="width:100%;height:100%;"
                  :id="'tb-' + index + '-' + indexs"
                  @click="ressrv(index, $event)"
                >
                  222
                </div> -->
                <div
                  style="width:100%;height:100%;"
                  v-if="shows(list.start, item, list.end) == 1"
                >
                  <div
                    v-if="list.status - 0"
                    class="reservation-cont-right-c-box-item-list-active"
                    @click="edit(indexs, k)"
                    :class="
                      overdue(list.end)
                        ? 'reservation-cont-right-c-box-item-list-active-overdue'
                        : ''
                    "
                  >
                    <p>{{ list.name }}</p>
                    <p>{{ list.phone }}</p>
                    <p>时长：{{ list.time }}分钟</p>
                    <p>备注：{{ list.other }}</p>
                  </div>

                  <div
                    class="reservation-cont-right-c-box-item-list-active-none"
                    :id="'tb-' + index + '-' + indexs"
                    @click="ressrv(index, indexs, k, $event)"
                    :class="
                      overdue(list.end)
                        ? 'reservation-cont-right-c-box-item-list-active-overdue'
                        : ''
                    "
                    v-if="list.status - 0 == 0"
                  >
                    <p>占用</p>
                  </div>
                </div>
                <div
                  class="reservation-cont-right-c-box-item-list-actives"
                  v-if="shows(list.start, item, list.end) == 2"
                  @click="edit(indexs, k)"
                  :class="
                    overdue(list.end)
                      ? 'reservation-cont-right-c-box-item-list-active-overdue'
                      : ''
                  "
                ></div>
                <div
                  class="reservation-cont-right-c-box-item-list-actives reservation-cont-right-c-box-item-list-active-img"
                  v-if="shows(list.start, item, list.end) == 3"
                  @click="edit(indexs, k)"
                  :class="
                    overdue(list.end)
                      ? 'reservation-cont-right-c-box-item-list-active-overdue'
                      : ''
                  "
                >
                  <span>图</span>
                </div>
                <!-- <div
                  
                  v-if="shows(false) == 5 && list.start"
                  @click="s(5)"
                >
                  <span>222222222</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 搜索 -->

    <div
      class="reservation-search"
      :class="searchStatus ? 'reservation-search-active' : ''"
    >
      <div class="reservation-search-head">
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="keys"
          class="input"
          @input="search"
        >
          <template slot="append" @click="search">
            <div @click="search">
              搜索
            </div>
          </template>
        </el-input>
        <el-button type="text" @click="searchs">取消</el-button>
      </div>
      <div class="reservation-search-cont">
        <div class="reservation-search-cont-box">
          <div class="reservation-search-cont-box-head">
            <span v-if="dateStatus">{{ dateStatus }}</span>
            <span v-else>{{ getDate() }} 今天 </span>
          </div>
          <div v-if="searchData.length">
            <div
              class="reservation-search-cont-box-list-box"
              v-for="(item, index) in searchData"
              :key="index"
            >
              <!-- @click="edit(index,item)" -->

              <div v-if="item.data.length">
                <div
                  v-for="(items, indexs) in item.data"
                  :key="indexs"
                  @click="edit(index, indexs)"
                >
                  <div
                    class="reservation-search-cont-box-list"
                    v-if="items.name"
                  >
                    <div class="reservation-search-cont-box-list-time">
                      {{ items.start.split(" ")[1] }}
                    </div>
                    <div class="reservation-search-cont-box-list-peopel">
                      <div class="reservation-search-cont-box-list-peopel-img">
                        <img :src="item.img" alt="" />
                      </div>
                      <div class="reservation-search-cont-box-list-peopel-text">
                        <div
                          class="reservation-search-cont-box-list-peopel-text-s"
                        >
                          {{ item.name }}
                        </div>
                        <div
                          class="reservation-search-cont-box-list-peopel-text-s reservation-search-cont-box-list-peopel-text-c"
                        >
                          {{ item.number }}
                        </div>
                      </div>
                    </div>
                    <div class="reservation-search-cont-box-list-other">
                      <span>{{ items.name }} ({{ items.phone }})</span>
                      <span>{{ minutes(items.start, items.end) }} 分钟</span>
                      <!-- <span>{{ items.type }}</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="reservation-search-cont-box-list-box-none"
            v-if="!searchData.length"
          ></div>
        </div>
      </div>
    </div>

    <!-- 搜索 -->

    <!-- 点击格子显示预约选项 -->
    <el-dialog
      title="选择预约类型"
      :visible.sync="centerDialogVisible"
      width="60%"
      center
    >
      <div class="type-img">
        <div @click="alertsShow(0)">
          <img
            src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_AddRes_MR.png?v=1"
            alt=""
          />
          <p>美容预约</p>
        </div>
        <div @click="alertsShow(1)">
          <img
            src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_AddRes_MF.png?v=1"
            alt=""
          />
          <p>通用预约</p>
        </div>
        <div @click="ressrvType(0)">
          <img
            src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_AddRes_ZY.png?v=1"
            alt=""
          />
          <p>设置占用</p>
        </div>
      </div>
    </el-dialog>
    <!-- 点击格子显示预约选项 -->

    <!-- 点击预约提醒 -->
    <el-dialog title="预约消息" :visible.sync="dialogVisible" width="60%">
      暂无待处理预约！

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 点击预约提醒 -->

    <!-- 预约选项 -->

    <div class="alerts">
      <el-dialog
        :visible.sync="centerDialogVisible1"
        :show-close="false"
        width="100%"
      >
        <div class="alerts-box">
          <div class="alerts-box-left" v-if="centerDialogVisible1Type == 0">
            <div class="alerts-box-left-head">
              <button
                class="alerts-box-left-head-btn"
                @click="centerDialogVisible1 = false"
              ></button>
              <div class="alerts-box-left-head-cont">
                <span
                  @click="tabClick(1)"
                  :class="!headIndex ? 'alerts-box-left-head-cont-span' : ''"
                  >门店项目</span
                >
                <span
                  @click="tabClick(2)"
                  :class="headIndex ? 'alerts-box-left-head-cont-span' : ''"
                  >会员项目</span
                >
              </div>
            </div>
            <div class="alerts-box-left-cont">
              <div v-if="!headIndex">
                <div class="alerts-box-left-cont-head-box">
                  <div
                    class="alerts-box-left-cont-head"
                    :style="{ height: headmore ? 'auto' : '44px' }"
                  >
                    <button
                      @click="headmore = !headmore"
                      :class="headmore ? 'alerts-box-left-cont-head-btn' : ''"
                    ></button>
                    <span
                      :class="
                        headDateHead == index
                          ? 'alerts-box-left-cont-head-span'
                          : ''
                      "
                      @click="headDateHead = index"
                      v-for="(item, index) in headDate"
                      :key="index"
                      >{{ item.name }}</span
                    >
                  </div>
                  <div class="alerts-box-left-cont-box">
                    <div
                      class="alerts-box-left-cont-box-item"
                      v-for="(item, index) in headDate[headDateHead].list"
                      :key="index"
                      @click="addlist(item)"
                    >
                      <div class="alerts-box-left-cont-box-item-img">
                        <img
                          :src="item.picture"
                          :alt="item.name"
                          v-if="item.picture"
                        />
                        <img
                          src="../../assets/none.png"
                          :alt="item.name"
                          v-if="!item.picture"
                        />
                      </div>
                      <div class="alerts-box-left-cont-box-item-num">
                        {{ item.name }}
                      </div>
                      <div class="alerts-box-left-cont-box-item-num">
                        {{ item.price }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="headIndex">
                <div class="alerts-box-left-search">
                  <el-input
                    placeholder="请输入搜索关键字..."
                    prefix-icon="el-icon-search"
                    v-model="tabKey"
                    @input="tabSearch"
                  >
                  </el-input>
                </div>
                <el-table
                  :data="tabSearchDate"
                  style="width: 100%"
                  @row-click="rowClick"
                >
                  <el-table-column prop="name" label="名称">
                    <template slot-scope="scope">
                      <div
                        style="display: flex;align-items: center;justify-content: flex-start;"
                      >
                        <button
                          class="alerts-box-left-search-btn"
                          :class="
                            scope.row.id == rowIndex
                              ? 'alerts-box-left-search-btn-active'
                              : ''
                          "
                        ></button>
                        {{ scope.row.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="balance_count" label="剩余次数">
                  </el-table-column>
                  <el-table-column prop="total_amount" label="单价">
                  </el-table-column>
                  <el-table-column prop="exp" label="有效期"> </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div class="alerts-box-left" v-if="centerDialogVisible1Type == 1">
            <div
              class="alerts-box-left-head"
              style="border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);"
            >
              <button class="alerts-box-left-head-btn" @click="hides"></button>
              <div class="alerts-box-left-head-cont">
                <span style="text-align: center;font-size: 18px;color: #28282D;"
                  >新增预约</span
                >
              </div>
            </div>
            <div class="alerts-box-left-cont">
              <div
                class="alerts-box-left-cont-box"
                style="background: #f4f4f4;"
              >
                <div
                  class="alerts-box-left-cont-box-items"
                  @click="tab1DataClick(index)"
                  :class="
                    tab1DataIndex == index
                      ? 'alerts-box-left-cont-box-items-active'
                      : ''
                  "
                  v-for="(item, index) in tab1Data"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="alerts-box-right">
            <div class="alerts-box-right-head">
              <span>预约信息</span>
              <div class="alerts-box-right-head-type">
                <button
                  class="alerts-box-right-head-type-btn"
                  :class="
                    !headIndex1 ? 'alerts-box-right-head-type-btn-active' : ''
                  "
                  @click="tabClick(3)"
                >
                  散客
                </button>
                <button
                  class="alerts-box-right-head-type-btn"
                  :class="
                    headIndex1 ? 'alerts-box-right-head-type-btn-active' : ''
                  "
                  @click="tabClick(4)"
                >
                  会员
                </button>
              </div>
            </div>
            <div
              class="alerts-box-right-vip"
              v-if="userInfo.name && headIndex1 == 1"
            >
              <div class="alerts-box-right-vip-img">
                <img :src="userInfo.img" alt="" />
              </div>
              <div class="alerts-box-right-vip-text">
                <div class="alerts-box-right-vip-text-name">
                  {{ userInfo.name }}
                </div>
                <div class="alerts-box-right-vip-text-other">
                  {{ userInfo.balance }}
                </div>
              </div>
              <button @click="tabClick(4)"></button>
            </div>
            <div class="alerts-box-right-list">
              <div class="alerts-box-right-list-item">
                <span>顾客姓名</span>
                <el-input
                  v-model="tabUserData.name"
                  placeholder="请输入顾客姓名"
                ></el-input>
              </div>
              <div class="alerts-box-right-list-item">
                <span>顾客电话</span>
                <el-input
                  v-model="tabUserData.phone"
                  placeholder="请输入顾客电话"
                ></el-input>
              </div>
            </div>

            <div class="alerts-box-right-list">
              <div class="alerts-box-right-list-item">
                <span>预约员工</span>
                <span class="pointer" @click="userAlert = !userAlert"
                  >{{ user[userIndex || 0].name }}({{
                    user[userIndex || 0].number
                  }})</span
                >
              </div>
              <div class="alerts-box-right-list-item">
                <span>预约时间</span>
                <span class="pointer" @click="date_time"
                  >{{ tabUserData.start || getDate() }}
                  {{ !tabUserData.start ? time[timeIndex] : "" }}</span
                >
              </div>
              <div class="alerts-box-right-list-item">
                <span>预约时长</span>
                <span class="pointer" @click="timeAlert = !timeAlert">{{
                  tabUserData.time + "分钟" || "请选择预约时长"
                }}</span>
              </div>
              <div class="alerts-box-right-list-item">
                <span>到店人数</span>
                <div class="alerts-box-right-list-items">
                  <el-input-number
                    v-model="tabUserData.num"
                    :min="1"
                    :max="100"
                    label="描述文字"
                  ></el-input-number>
                </div>
              </div>
              <div class="alerts-box-right-list-item">
                <span>预约备注</span>
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="tabUserData.other"
                  maxlength="300"
                  show-word-limit
                >
                </el-input>
              </div>
            </div>

            <div>
              <div class="alerts-box-right-title">预约项目</div>
              <div style="height:230px;overflow-y: auto;">
                <div
                  class="alerts-box-right-lists"
                  v-for="(item, index) in tabUserData.data"
                  :key="index"
                >
                  <button @click="dellist(index)"></button>
                  <span>{{ item.name || item }}</span>
                </div>
              </div>
            </div>
            <div>
              <el-button style="width:100%" @click="success(0)" type="primary"
                >预约</el-button
              >
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 预约选项 -->

    <!-- 选择预约时间 -->
    <el-dialog title="预约时间" :visible.sync="dateAlert" width="80%">
      <div style="display:flex;">
        <div style="width:100%">
          <dates @choseDay="clickDay" :markDate="markDate" />
        </div>
        <div class="time-alert" v-if="tabTimeLise.length">
          <div
            class="time-alert-list"
            :class="tabTimeIndex == index ? 'time-alert-list-active' : ''"
            v-for="(item, index) in tabTimeLise"
            :key="index"
          >
            <p v-if="item.type" @click="setTabTime(-1)">
              <span>{{ item.time }}</span>
              <span>已预约</span>
            </p>
            <p v-else @click="setTabTime(index)">
              <span>{{ item.time }}</span>
              <span></span>
            </p>
          </div>
        </div>
        <div v-else class="time-alert">当日无可预约时间！</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dateAlert = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择预约时间 -->

    <!-- 选择服务时长 -->
    <el-dialog title="服务时长" :visible.sync="timeAlert" width="60%">
      <div>
        <el-slider
          v-model="tabUserData.time"
          :step="30"
          show-stops
          :max="300"
          :min="30"
        >
        </el-slider>
        <div style="display:flex;">
          <div style="width:100%;text-align:left;">30分钟</div>
          <div style="width:100%;text-align:right;">300分钟</div>
        </div>
        <div style="display:flex;margin-top:50px;">
          <div style="width:100%;text-align:left;">
            服务时长
            <span style="color:red">{{ tabUserData.time }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="timeType">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择服务时长 -->

    <!-- 选择员工 -->

    <el-dialog title="预约员工" :visible.sync="userAlert" width="60%">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="item.name"
          :name="index + ''"
          v-for="(item, index) in tabData"
          :key="index"
        >
          <div class="tabData-box">
            <div
              class="tabData-box-list"
              v-for="(item, index) in tabList"
              :key="index"
              :class="tabId == item.id ? 'tabData-box-list-active' : ''"
              @click="userClick(item.id)"
            >
              <div class="tabData-box-list-img">
                <img
                  src="https://static.bokao2o.com/images/bg200.png?imageView2/1/w/200/h/200/interlace/1/q/100"
                  alt=""
                />
              </div>
              <div class="tabData-box-list-name">{{ item.name }}</div>
              <div class="tabData-box-list-number">{{ item.number }}</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="userAlert = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 选择员工 -->

    <!-- 选择会员 -->

    <el-dialog title="提示" :visible.sync="usershows" width="30%">
      <user />
    </el-dialog>
    <!-- 选择会员 -->

    <!-- 预约编辑 -->

    <div class="edit-box">
      <el-dialog
        title="预约信息"
        :visible.sync="editAlert"
        width="50%"
        :show-close="false"
        :before-close="editClose"
      >
        <div class="edit-box-cont" v-if="editAlert">
          <div class="edit-box-cont-info">
            <div class="edit-box-cont-info-list">
              <div class="edit-box-cont-info-list-item">顾客姓名</div>
              <div class="edit-box-cont-info-list-item">
                {{ tabUserData.name }}
              </div>
            </div>
            <div class="edit-box-cont-info-list">
              <div class="edit-box-cont-info-list-item">顾客电话</div>
              <div class="edit-box-cont-info-list-item">
                {{ tabUserData.phone }}
              </div>
            </div>
            <div class="edit-box-cont-info-list">
              <div class="edit-box-cont-info-list-item">预约项目</div>
              <div class="edit-box-cont-info-list-item">
                {{ tabUserData.data.toString() }}
              </div>
            </div>
          </div>
          <div class="edit-box-cont-info" v-if="!editType">
            <div class="edit-box-cont-info-list">
              <div class="edit-box-cont-info-list-item">预约员工</div>
              <div class="edit-box-cont-info-list-item">
                {{ tabUserData.workname }}
              </div>
            </div>
            <div class="edit-box-cont-info-list">
              <div class="edit-box-cont-info-list-item">到店日期</div>
              <div class="edit-box-cont-info-list-item">
                {{ tabUserData.start }}
              </div>
            </div>
            <div class="edit-box-cont-info-list">
              <div class="edit-box-cont-info-list-item">预约时长</div>
              <div class="edit-box-cont-info-list-item">
                {{ minutes(tabUserData.start, tabUserData.end) }} 分钟
              </div>
            </div>
            <div class="edit-box-cont-info-list">
              <div class="edit-box-cont-info-list-item">到店人数</div>
              <div class="edit-box-cont-info-list-item">
                {{ tabUserData.num }} 人
              </div>
            </div>
            <div class="edit-box-cont-info-list">
              <div class="edit-box-cont-info-list-item">预约备注</div>
              <div
                class="edit-box-cont-info-list-item"
                v-if="tabUserData.other"
              >
                {{ tabUserData.other }}
              </div>
              <div
                class="edit-box-cont-info-list-item"
                style="color:#eee;"
                v-else
              >
                无备注...
              </div>
            </div>
          </div>
          <div class="edit-box-cont-info edit-box-cont-infos" v-else>
            <div class="edit-box-cont-info-list" @click="userAlert = true">
              <div class="edit-box-cont-info-list-item">预约员工</div>
              <div
                class="edit-box-cont-info-list-item edit-box-cont-info-list-items"
              >
                {{ tabUserData.workname }}
              </div>
            </div>
            <div class="edit-box-cont-info-list" @click="date_time">
              <div class="edit-box-cont-info-list-item">到店日期</div>
              <div
                class="edit-box-cont-info-list-item edit-box-cont-info-list-items"
              >
                {{ tabUserData.start }}
              </div>
            </div>
            <div class="edit-box-cont-info-list" @click="timeAlert = true">
              <div class="edit-box-cont-info-list-item">预约时长</div>
              <div
                class="edit-box-cont-info-list-item edit-box-cont-info-list-items"
              >
                {{ tabUserData.time }}分钟
              </div>
            </div>
            <div class="edit-box-cont-info-list">
              <div class="edit-box-cont-info-list-item">到店人数</div>
              <div class="edit-box-cont-info-list-item">
                <el-input-number
                  v-model="tabUserData.num"
                  :min="1"
                  :max="100"
                  label="描述文字"
                ></el-input-number>
              </div>
            </div>
            <div class="edit-box-cont-info-list">
              <div class="edit-box-cont-info-list-item">预约备注</div>
              <div class="edit-box-cont-info-list-item">
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="tabUserData.other"
                  maxlength="300"
                  show-word-limit
                >
                </el-input>
              </div>
            </div>
          </div>

          <button class="edit-box-cont-close" @click="editClose"></button>
          <button
            class="edit-box-cont-text"
            @click="editType = !editType"
            v-if="
              !editType && tabUserData.Status > -1 && tabUserData.Status != 1
            "
          >
            编辑
          </button>
          <button
            class="edit-box-cont-text"
            @click="success(0, 1)"
            v-if="editType"
          >
            完成
          </button>
        </div>
        <div
          slot="footer"
          class="dialog-footer1"
          v-if="tabUserData.Status == -1"
        >
          <el-button type="info">预约已取消</el-button>
          <!-- <el-button type="primary" @click="newRoute">开 单</el-button> -->
        </div>
        <div slot="footer" class="dialog-footer1" v-else>
          <el-button type="success" v-if="tabUserData.Status == 1"
            >已到店</el-button
          >
          <el-button
            @click="fails"
            v-if="!overdue(tabUserData.end) && tabUserData.Status != 1"
            >取消预约</el-button
          >
          <el-button
            type="primary"
            @click="newRoute"
            v-if="
              !overdue(tabUserData.end) &&
                tabUserData.Status != 1 &&
                timeStauts(tabUserData.end)
            "
            >开 单</el-button
          >
          <el-button
            type="primary"
            @click="editType = !editType"
            v-if="
              !editType && overdue(tabUserData.end) && tabUserData.Status != 1
            "
            >编辑</el-button
          >
          <el-button
            type="primary"
            @click="success(0, 1)"
            v-if="
              editType && overdue(tabUserData.end) && tabUserData.Status != 1
            "
            >完成</el-button
          >
        </div>
      </el-dialog>
    </div>

    <!-- 预约编辑 -->
  </div>
</template>

<script>
import dates from "vue-calendar-component";
import user from "../../components/home/selectVip.vue";
export default {
  components: {
    dates,
    user,
  },
  data() {
    return {
      activeName: "",
      centerDialogVisible: false,
      dialogVisible: false,
      centerDialogVisible1: false,
      centerDialogVisible1Type: false,
      timeAlert: false,
      dateAlert: false,
      userAlert: false,
      editAlert: false,
      time: [],
      timeIndex: "",
      userIndex: "",
      user: [
        {
          id: "0",
          name: "刘备",
          type: "1",
          img:
            "https://img1.bokao2o.com/Fnqnmeeipt__kFFReFZTTB5QA3Ek?imageView2/1/w/200/h/200/interlace/1/q/100",
          number: "0001",
          data: [],
        },
        {
          id: "1",
          name: "关羽",
          type: "2",
          img:
            "https://img1.bokao2o.com/Fnqnmeeipt__kFFReFZTTB5QA3Ek?imageView2/1/w/200/h/200/interlace/1/q/100",
          number: "0002",
          data: [],
        },
        {
          id: "2",
          name: "孙悟空",
          img: "",
          number: "0003",
          type: "3",
          data: [],
        },
        { id: "5", name: "后裔", img: "", number: "0004", type: "4", data: [] },
        { id: "6", name: "墨子", img: "", number: "0005", type: "5", data: [] },
      ],
      lineX: 0, //时间线的坐标
      dateStatus: "", //头部日期显示
      focustype: false, //点击头部时间显示日历
      value: "",
      keys: "", //搜索关键字
      searchStatus: false, //搜索标识
      searchData: [], //搜索数据
      headIndex: 0, //头部标题选项
      headIndex1: 0, //右侧头部标题选项
      headDate: [
        {
          id: "0",
          name: "常用",
          list: [{ id: "0", name: "剪发", price: 999 }],
        },
        {
          id: "1",
          name: "洗吹",
          list: [{ id: "0", name: "洗发", price: 999 }],
        },
      ],
      headmore: false, //头部菜单展开
      headDateHead: 0, //菜单头部下标
      tableData: [], //会员项目
      tabSearchDate: [], //搜索数据
      tabKey: "", //会员项目搜索关键字
      tabData: [
        { name: "全部" },
        { name: "美发经理" },
        { name: "发型师" },
        { name: "美容师" },
        { name: "技师" },
        { name: "助理技师" },
        { name: "督导" },
      ],
      tabId: "", //选中人员id
      tabList: [], //切换显示工作人员数据
      tabTimeLise: [], //预约时间列表
      tabTimeIndex: -1, //选择预约时间的下标
      tabUserData: {
        id: "2",
        workId: "",
        name: "",
        phone: "",
        type: "",
        num: "",
        typeImg: "",
        time: 30,
        times: "",
        other: "",
        status: "1",
        start: "",
        data: [],
        end: "",
      },
      tab1Data: ["洗护", "剪发", "烫发", "染发", "接发"],
      tab1DataIndex: -1, //普通预约项目下标
      rowIndex: -1, //会员项目下标
      editType: false, //编辑标识
      userDataIndex: -1, //员工预约订单下标
      markDate: [],
      bgColor: [],
    };
  },
  methods: {
    /* 关闭选择项目窗口 */
    hides() {
      this.centerDialogVisible1 = false;
      this.tabTimeIndex = -1;
      this.headIndex1 = 0;
      this.tab1DataIndex = -1;
      this.tabUserData.data = [];
      this.$store.commit("setUser", []);
    },
    /* 随机颜色 */
    rounds(len) {
      let getRandomColor = function() {
        return (
          "rgb(" +
          Math.round(Math.random() * 255) +
          "," +
          Math.round(Math.random() * 255) +
          "," +
          Math.round(Math.random() * 10) +
          ")"
        );
      };

      for (let i = 0; i < len; i++) {
        this.bgColor.push(getRandomColor());
      }
      console.log(this.bgColor);
    },
    calssStatus() {
      if (this.tabStatuss && this.navStatuss) {
        return "home-head-w";
      }
      if (!this.tabStatuss && this.navStatuss) {
        return "home-head-w home-head-h";
      }

      if (!this.tabStatuss && !this.navStatuss) {
        return "home-head-hs";
      }
    },
    /* 显示会员选项 */
    tabClick(index) {
      if (index == 3 && this.headIndex1 == 1) {
        this.$confirm("确定要放弃使用会员卡吗, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.headIndex1 = 0;
            this.headIndex = 0;
            this.tabUserData.name = "";
            this.tabUserData.phone = "";
          })
          .catch(() => {
            this.headIndex1 = 1;
          });
        return false;
      }
      if (index == 3) {
        this.headIndex1 = 0;
      }
      if (index == 4) {
        this.headIndex1 = 1;
        this.$store.commit("selectVipShows", true);
      }

      if (index == 1) {
        this.headIndex = 0;
      }
      if (index == 2) {
        this.headIndex = 1;
        this.headIndex1 = 1;
        this.$store.commit("selectVipShows", true);
      }
    },
    /* 获取时间段 */
    times(n, n1) {
      let arr = [];
      let num = 0; //是否第一次调用
      time(n, n1);
      function time(n, n1) {
        // 第一次调用默认添加第一个
        if (!num) {
          arr.push(n + ":00");
        }
        // 大于的时候跳出递归
        if (n >= n1) {
          return false;
        }
        let s = n + 0.5;
        num++;
        //转换成字符串
        let b = s.toString();
        //存在小数点就改成30
        let a = b.includes(".") ? b.split(".")[0] + ":30" : b + ":00";
        arr.push(a);
        time(s, n1);
      }
      this.time = arr;
    },
    shows(obj, obj1, obj2) {
      /*
        obj：开始时间
        obj1：时间导航（头部时间）
        obj2：结束时间
        */

      /* 占位 */
      // if (!obj && !obj1 && !obj2) {
      //   return 5;
      // }

      if (obj && obj1 && obj2) {
        let t1 = obj.split(" "); //开始时间
        let t2 = obj1.replace(":", ""); //时间导航（头部时间）
        let t3 = obj2.split(" "); //结束时间

        let d1 = t1[1].split(":"); //开始时间
        let d2 = t2 - 0; //时间导航（头部时间）
        let d3 = t3[1].split(":"); //结束时间

        d1 = d1[0] + d1[1] - 0; //开始时间
        d3 = d3[0] + d3[1] - 0; //结束时间

        // console.log(d1, d2, d3);
        // if (obj == obj2) {
        //   return 4;
        // }
        /* 开始时间等于导航时间（显示用户资料） */
        if (d1 == d2) {
          return 1;
        }
        /* 预约范围内的格子显示 */
        // if (d2 >= d1 && d2 <= d3) {
        //   if (d2 <= d3) {
        //     return 0;
        //   } else {
        //     return 2;
        //   }
        // }
        if (d2 >= d1 && d2 <= d3) {
          if (d1 < 1000 && d3 < 1000) {
            if (d2 <= d3) {
              return 0;
            } else {
              return 2;
            }
          }
          if (d2 < d3) {
            return 2;
          }
        }
        /* 不存在预约的格子 */
        return 0;
      } else {
        /* 占位 */
        return 5;
      }
    },
    /* 过期 */
    overdue(obj) {
      if (!obj) {
        return false;
      }
      let time = this.dateStatus ? this.dateStatus.split(" ")[0] : "";
      let date = [new Date(this.getDate()).getTime(), new Date(time).getTime()];

      let h = new Date().getHours();
      let m = new Date().getMinutes();
      m = m <= 9 ? "0" + m : m;
      let hs = h + "" + m - 0;

      let t = obj.split(" "); //结束时间
      let d = t[1].split(":"); //结束时间
      d = d[0] + d[1] - 0; //结束时间
      /* 过去时间 */
      if (date[0] > date[1]) {
        return true;
      }
      /* 未来时间 */
      if (date[0] < date[1]) {
        return false;
      }
      /* 当前时间 */
      if (hs >= d) {
        return true;
      }
      return false;
    },
    /* 时间线位置 */
    line(s) {
      let _this = this;
      setInterval(() => {
        let this_time = new Date().getTime();
        let start = new Date(this.getDate() + " " + s + ":00").getTime();
        // let end = new Date(this.getDate() + " " + e + ":00").getTime();
        let num = ((this_time - start) / 1000 / 60) * 4.5;
        let x = Math.floor(num);
        _this.lineX = x;
      }, 1000);
    },
    /* 判断当前时间是否等于预约日期，等于显示开单按钮，否则不显示 */
    timeStauts(time) {
      let t = new Date(this.getDate()).getTime();
      let times = time.split(" ")[0];
      let e = new Date(times).getTime();

      if (t == e) {
        return true;
      } else {
        return false;
      }
    },
    /* 年月日 周 */
    getDate(obj) {
      let date = new Date();
      let seperator1 = "-";
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let currentdate = year + seperator1 + month + seperator1 + strDate;

      // console.log(year, seperator1 , month , seperator1 , strDate)

      if (obj) {
        let arr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
        let d = date.getDay();
        return arr[d];
      }

      return currentdate;
    },
    formatDate(type, str) {
      // this.dateStatus = false;
      let t = this.dateStatus
        ? this.dateStatus.split(" ")[0] + " 00:00:00"
        : str;
      let num = type
        ? new Date(t).getTime() + 86400000
        : new Date(t).getTime() - 86400000;
      let date = new Date(num);
      let YY = date.getFullYear() + "-";
      let MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let d = date.getDay();
      let arr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];

      // return
      this.dateStatus = YY + MM + DD + " " + arr[d];
      this.getyue(YY + MM + DD); //获取预约列表
      this.clickDay(YY + MM + DD, 1);
      this.markDate = [YY + MM + DD];
      // getyue
    },
    /* 点击预约操作 */
    ressrv(time, user, index, html) {
      /*
      time:头部时间下标。用来同步时间
      user:员工下标
      index:员工预约下标
      html:点击元素，用来判断是否占用
      */

      let h = new Date().getHours();
      let m = new Date().getMinutes();
      m = m <= 9 ? "0" + m : m;
      let hs = h + "" + m - 0;
      let d = this.time[time].split(":"); //结束时间
      d = d[0] + d[1] - 0; //结束时间

      let Dates = this.dateStatus ? this.dateStatus.split(" ")[0] : "";
      // console.log(this.dateStatus)

      let Date_o = new Date(this.getDate()).getTime(); //当前时间
      let Date_w = new Date(Dates).getTime(); //选择时间

      console.log(Date_o, Date_w);

      if (Date_w < Date_o) {
        this.$message({
          message: "时间已过不可以进行操作了哦~",
          type: "warning",
        });
        return true;
      }

      if (Date_w == Date_o || !Dates) {
        if (hs >= d) {
          this.$message({
            message: "时间已过不可以进行操作了哦~",
            type: "warning",
          });
          return true;
        }
      }

      let _this = this;
      this.timeIndex = time;
      this.userIndex = user;

      if (this.user[user].data.length) {
        if (this.user[user].data[index].status == 0 && html.path[0].innerText) {
          this.$confirm("该时段已占用，是否设为空闲状态?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              if (_this.user[user].data[index].name) {
                _this.user[user].data[index].status = "1";
              } else {
                /* 如果没有会员信息的直接删除数据 */
                _this.user[user].data.splice(index, 1);
              }
            })
            .catch(() => {});
          return false;
        }
      }

      this.centerDialogVisible = true;
    },
    /* 显示预约类型 */
    ressrvType(obj) {
      // console.log(obj)
      if (!obj) {
        this.centerDialogVisible = !this.centerDialogVisible;
        let data = {
          id: "2",
          name: "",
          phone: "",
          type: "",
          typeImg: "",
          other: "",
          status: "0",
          time: 30,
          start: this.getDate() + " " + this.time[this.timeIndex] + ":00",
          end: this.getDate() + " " + this.time[this.timeIndex] + ":00",
        };

        this.user[this.userIndex].data.push(data);
      }
    },
    focuss() {
      this.$refs.saveDateInput.$refs.reference.$refs.input.focus();
    },
    change() {
      // console.log(this.formatDate(0,this.value))
      let date = this.value;
      let seperator1 = "-";
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let arr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      let d = date.getDay();
      let currentdate =
        year + seperator1 + month + seperator1 + strDate + " " + arr[d];

      this.dateStatus = currentdate;
      this.getyue(year + seperator1 + month + seperator1 + strDate);

      this.clickDay(year + "/" + month + "/" + strDate, 1);
      this.markDate = [year + "/" + month + "/" + strDate];
      // console.log(currentdate);
    },
    /* 计算分钟数 */
    minutes(s, e) {
      // console.log(s, e);
      // let t = s.split(" ")[1];
      // let d = e.split(" ")[1];

      // t = t.replace(/:/g, "") - 0;
      // d = d.replace(/:/g, "") - 0;

      // let num = Math.round((d - t) / 100 / 60) * 30;

      let num = (new Date(e).getTime() - new Date(s).getTime()) / 60 / 1000;
      // console.log(num)
      return num;
    },
    /* 点击显示搜索界面，显示默认预约数据，关闭清空 */
    searchs() {
      this.searchStatus = !this.searchStatus;
      if (this.searchStatus) {
        this.searchData = this.user;
      } else {
        this.searchData = [];
      }
    },
    /* 搜索 */
    search() {
      let d = JSON.parse(JSON.stringify(this.user));
      let len = d.length;
      let arr = [];

      for (let i = 0; i < len; i++) {
        arr.push(JSON.parse(JSON.stringify(d[i])));
        arr[i].data = [];

        for (let k = 0; k < d[i].data.length; k++) {
          if (
            d[i].name.includes(this.keys) ||
            d[i].number.includes(this.keys) ||
            d[i].data[k].name.includes(this.keys)
          ) {
            arr[i].data.push(d[i].data[k]);
          }
        }
      }
      this.searchData = arr;
    },
    /* 点击显示预约选项界面 */
    alertsShow(obj) {
      this.centerDialogVisible1 = !this.centerDialogVisible1;
      this.centerDialogVisible1Type = obj;
      console.log(this.centerDialogVisible1Type);
    },
    /* 选中会员项目 */
    rowClick(row) {
      console.log(row);
      this.rowIndex = row.id;

      if (!this.tabUserData.data.length) {
        this.tabUserData.data.push(row);
        return false;
      }
      /* 点击相同的去掉 */
      for (let i = 0; i < this.tabUserData.data.length; i++) {
        if (this.tabUserData.data[i].name == row.name) {
          console.log;
        } else {
          this.tabUserData.data.push(row);
        }
      }

      let obj = {};

      let peon = this.tabUserData.data.reduce((cur, next) => {
        obj[next.id] ? "" : (obj[next.id] = true && cur.push(next));
        return cur;
      }, []); //设置cur默认类型为数组，并且初始值为空的数组

      // console.log(peon)
      this.tabUserData.data = peon;
    },
    /* 会员项目搜索 */
    tabSearch() {
      let d = this.tableData;
      let len = d.length;
      this.tabSearchDate = [];
      for (let i = 0; i < len; i++) {
        if (d[i].number.includes(this.tabKey)) {
          this.tabSearchDate.push(d[i]);
        }
      }
    },
    clickDay(data, type) {
      console.log(data);
      let d = data.includes("/") ? data.split("/") : data.split("-");
      let y = d[0];
      let m = d[1] - 0 < 10 ? "0" + (d[1] - 0) : d[1];
      let de = d[2] - 0 < 10 ? "0" + (d[2] - 0) : d[2];

      let ys = new Date().getFullYear();
      let ms = new Date().getMonth() + 1;
      ms = ms < 10 ? "0" + ms : ms;
      let ds = new Date().getDate();
      ds = ds < 10 ? "0" + ds : ds;
      if (y == ys && ms == m && de == ds) {
        this.timeList();
      } else {
        this.timeList(y + "-" + m + "-" + de);
      }
      let index = this.tabTimeIndex;
      if (index >= 0) {
        if (this.tabTimeLise[index]) {
          this.tabUserData.start =
            y + "-" + m + "-" + de + " " + this.tabTimeLise[index].time + ":00";
        } else {
          this.tabUserData.start = y + "-" + m + "-" + de;
        }
      }
      this.tabUserData.start = y + "-" + m + "-" + de;

      this.markDate = [y + "/" + m + "/" + de];

      let arr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      let arrindex = new Date(y + "-" + m + "-" + de).getDay();
      this.dateStatus = y + "-" + m + "-" + de + " " + arr[arrindex];
      this.getyue(this.tabUserData.start, type);
      // this.change();
      // this.markDate = year+"/"+month+"/"+strDate;
      return y + "-" + m + "-" + de;
    },
    date_time() {
      this.dateAlert = !this.dateAlert;
      let Dates = this.dateStatus ? this.dateStatus.split(" ")[0] : "";

      if (this.getDate() == Dates) {
        Dates = "";
        this.timeList();
        return false;
      }

      // alert(Dates)

      // let Date_o = new Date().getTime(); //当前时间
      // let Date_w = new Date(Dates).getTime(); //选择时间
      this.timeList(Dates);
    },
    /* 切换员工分类 */
    handleClick(obj) {
      console.log(obj, this.user);
      let index = obj.index - 0;
      let d = this.user;
      let len = d.length;
      this.tabList = [];
      if (!index) {
        this.tabList = this.user;
      } else {
        for (let i = 0; i < len; i++) {
          if (this.tabData[index].id == d[i].worktype_id) {
            this.tabList.push(d[i]);
          }
        }
      }
      console.log(index);
    },
    /* 选中的工作人员 */
    userClick(id) {
      this.tabId = id;
      let d = this.user;
      let len = d.length;
      for (let i = 0; i < len; i++) {
        if (id == d[i].id) {
          this.userIndex = i;
          this.tabUserData.workId = d[i].id;
          console.log(this.userIndex, this.tabUserData.workId);
        }
      }
    },
    /* 获取预约时间 */
    timeList(obj) {
      // console.log(obj,this.user,"dsfsdfsdfsdfsdfsdf");
      this.tabTimeLise = [];
      let date = "";
      if (obj) {
        date = new Date(obj);
      } else {
        date = new Date();
      }
      let h = date.getHours() + "";
      let m =
        date.getMinutes() > 9
          ? date.getMinutes() + ""
          : "0" + date.getMinutes();
      let t = h + m - 0;
      let d = this.time;
      let len = d.length;
      let index = this.userIndex != "" ? this.userIndex : 0;
      let _this = this;

      let arr = [];
      let datas = [];

      for (let i = 0; i < len; i++) {
        let k = d[i].replace(/:/, "") - 0;
        if (k > t) {
          arr.push(d[i]);
        }
      }
      // console.log(arr)

      for (let l = 0; l < arr.length; l++) {
        times(arr[l]);
      }

      function times(obj) {
        /* 一格是30分钟 所以要加1，如预约9点会显示9.30也在预约，9.30其实就是下一格子的 */

        let t = obj.replace(/:/, "") - 0 + 1;
        // let objs = obj.split(":");
        // let objTime = null;
        // if(objs[1]=="30"){
        //   objTime = objs[0] + ":" + (objs[1]-0+1);
        // }
        if (_this.user[index].data.length) {
          for (let n = 0; n < _this.user[index].data.length; n++) {
            let end = _this.user[index].data[n].end.split(" ")[1];
            let start = _this.user[index].data[n].start.split(" ")[1];
            let ends = end.split(":");
            let starts = start.split(":");
            let e = ends[0] + ends[1] - 0; //结束时间
            let s = starts[0] + starts[1] - 0; //开始时间
            // console.log(t,e,end,s,start)
            if (t <= e && t >= s) {
              if (!datas[datas.length]) {
                datas.push({ time: obj, type: true });
              }
            } else {
              if (datas[datas.length - 1]) {
                if (datas[datas.length - 1].time != obj) {
                  datas.push({ time: obj, type: false });
                }
              } else {
                datas.push({ time: obj, type: false });
              }
            }
          }
        } else {
          if (!datas[datas.length]) {
            datas.push({ time: obj, type: false });
          }
        }
      }

      /* 去重，去掉重复的时间 */
      let timeData = datas;

      for (let i = 0; i < datas.length; i++) {
        for (let k = 0; k < datas.length; k++) {
          if (datas[i].time == datas[k].time) {
            if (datas[i].type != datas[k].type) {
              timeData.splice(i, 1);
            }
          }
        }
      }

      // console.log(111)

      // if (obj) {
      //   timeData = [];
      //   if (date.getTime() < new Date().getTime()) {
      //     this.tabTimeLise = [];
      //   } else {
      //     for (let i = 0; i < this.time.length; i++) {
      //       timeData.push({ time: this.time[i], type: false });
      //     }
      //   }
      //   console.log(22222)
      // }
      this.tabTimeLise = timeData;
      // console.log(datas)
    },
    /* 选择预约时间 */
    setTabTime(i) {
      let Dates = this.dateStatus ? this.dateStatus.split(" ")[0] : "";
      if (i < 0) {
        this.$message.error("该时间段已预约");
      } else {
        this.tabTimeIndex = i;
        this.tabUserData.start = Dates
          ? Dates + " " + this.tabTimeLise[i].time + ":00"
          : this.getDate() + " " + this.tabTimeLise[i].time + ":00";
      }
    } /* 普通预约项目 */,
    tab1DataClick(index) {
      this.tab1DataIndex = index;
      this.tabUserData.data.push(this.tab1Data[index]);
    },
    /* 添加项目 */
    addlist(item) {
      this.tabUserData.data.push(item.name);
    },
    /* 删除项目 */
    dellist(index) {
      this.tabUserData.data.splice(index, 1);
    },
    timeType() {
      this.success(1); //计算结束时间
      this.timeAlert = false;
    },
    /* 预约 */
    success(obj, type) {
      let d = JSON.parse(JSON.stringify(this.tabUserData));

      console.log(d, 111111111111111);
      d.status = "1";

      /* // 校验顾客姓名是否为中文
      if (!/^(?:[\u4e00-\u9fa5·]{2,16})$/.test(d.name)) {
        this.$message.error("请输入正确的顾客姓名");
        return false;
      } */

      if (
        !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[\d])|(?:5[\d])|(?:6[\d])|(?:7[\d])|(?:8[\d])|(?:9[\d]))\d{8}$/.test(
          d.phone
        )
      ) {
        this.$message.error("请输入正确的顾客手机号码");
        return false;
      }

      if (!this.tabId == "") {
        if (this.userIndex === "") {
          this.$message.error("请选择工作人员");
          return false;
        }
      }

      if (!d.start) {
        d.start = this.getDate();
      }
      if (!d.start.includes(":")) {
        this.$message.error("请选择预约时间");
        return false;
      }

      /* 分钟换成小时 */
      let t = (d.time - 0) / 60 + "";
      let ts = t.replace(/.5/, "30") - 0;
      ts = ts < 10 ? ts * 100 : ts;
      // ts = ts <

      /*  */
      let h = d.start.split(" ")[1];
      let hs = h.split(":");
      let ht = hs[0] + hs[1] - 0;
      let maxt = this.time[this.time.length - 1];
      let maxH = maxt.replace(/:/, "") - 0;
      let mins = ts + ht + "";

      // console.log(mins)

      let str = "";

      if (mins[2] == "6") {
        str = mins[0] + mins[1] - 0 + 1 + "" + ":00:00";
      } else {
        if (mins[3]) {
          str = mins[0] + mins[1] + ":" + mins[2] + mins[3] + ":00";
        } else {
          str = "0" + mins[0] + ":" + mins[1] + mins[2] + ":00";
        }
      }

      /* 时间超过当天时间 */
      if (ts + ht > 2400) {
        this.$message.error("预约时间不能跨天");
        return false;
      }

      /* 时间超过最大时间但是不超过当天时间 */
      if (ts + ht > maxH && ts + ht < 2400) {
        d.end =
          d.start.split(" ")[0] + " " + this.time[this.time.length - 1] + ":00";
      }

      d.end = d.start.split(" ")[0] + " " + str;

      /* 预约时间不能重叠 */
      for (let i = 0; i < this.user[this.userIndex].data.length; i++) {
        if (
          d.end.split(" ")[1].replace(/:/g, "") - 0 >
            this.user[this.userIndex].data[i].start
              .split(" ")[1]
              .replace(/:/g, "") -
              0 &&
          d.start.split(" ")[1].replace(/:/g, "") - 0 <
            this.user[this.userIndex].data[i].end
              .split(" ")[1]
              .replace(/:/g, "") -
              0
        ) {
          this.$message.error("员工已被预约，请选择其他员工1");
          return false;
        }
      }

      if (obj) {
        return false;
      }
      if (!d.data.length) {
        this.$message.error("请选择预约项目");
        return false;
      }

      // if(){}
      d.workId = this.userIndex;

      if (type) {
        this.setyue(d, 1); //修改预约
      } else {
        this.setyue(d); //添加预约
      }
      this.user[this.userIndex].data.push(d);
      this.centerDialogVisible1 = false;
      this.centerDialogVisible = false;
      if (!obj) {
        this.editAlert = false;
      }

      this.tabUserData = {
        id: "2",
        workId: "",
        name: "",
        phone: "",
        type: "",
        num: "",
        typeImg: "",
        time: 30,
        times: "",
        other: "",
        status: "1",
        start: "",
        data: [],
        end: "",
      };
      this.editType = false;
    },
    /* 取消预约 */
    fails() {
      this.$confirm("此操将取消预约, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.editAlert = false;
          console.log(this.tabUserData);

          this.setyue(this.tabUserData, 1, "-1");
        })
        .catch(() => {});

      // this.user[this.userIndex].data.splice(this.userDataIndex, 1);
    },
    /* 点击开启编辑 把编辑的数据先去掉，点击完成的时候在恢复 */
    edit(index, k) {
      let d = this.user;
      console.log(index, k, d[index].data[k]);
      this.editAlert = true;
      this.userDataIndex = k;
      this.userIndex = index;
      this.tabUserData = JSON.parse(JSON.stringify(d[index].data[k]));
      this.user[index].data.splice(k, 1);
    },
    /* 关闭编辑 */
    editClose() {
      let d = JSON.parse(JSON.stringify(this.tabUserData));
      this.user[this.userIndex].data.push(d);
      console.log(d, 2222222222222);
      this.tabUserData = {
        id: "2",
        workId: "",
        name: "",
        phone: "",
        type: "",
        num: "",
        typeImg: "",
        time: 30,
        times: "",
        other: "",
        status: "1",
        start: "",
        data: [],
        end: "",
      };
      this.editAlert = false;
      this.editType = false;
      /* 处理时间格式 关闭的时候数据没有同步，所以更新一下数据 */
      let datas = this.dateStatus.includes("周")
        ? this.dateStatus.splice(" ")[0]
        : this.dateStatus;
      this.getyue(datas, 0);
    },
    /* 开单 */
    newRoute() {
      this.$router.push({
        path: "/home",
        // query: { type: true },
      });
    },

    getyue(obj, type) {
      if (type) {
        return false;
      }
      // status: 0
      let date = obj || this.getDate();
      this.$axios({
        href: "/api/app/meiye/yue/store",
        data: { date: date, page: -1 },
      }).then((res) => {
        console.log(res);
        let d = res.data.items;
        let len = d.length;

        let d1 = this.user;
        let len1 = d1.length;

        for (let i = 0; i < len1; i++) {
          d1[i].data = [];
          for (let l = 0; l < len; l++) {
            if (d[l].staff_id == d1[i].id && d[l].status != -1) {
              d1[i].data.push({
                id: d[l].id,
                workId: d[l].staff_id,
                workname: d1[i].name,
                name: d[l].name,
                phone: d[l].phone,
                type: 1,
                num: 1,
                typeImg: "",
                time: this.minutes(d[l].time_begin, d[l].time_end),
                times: 1,
                other: d[l].remark,
                status: 1,
                Status: d[l].status,
                start: d[l].time_begin,
                data: d[l].project_name.includes("|")
                  ? d[l].project_name.split("|")
                  : [d[l].project_name],
                end: d[l].time_end,
              });
            }
          }
        }

        this.user = d1;
        console.log(d1);

        this.timeList(date);

        // console.log(d, len, this.user, d1);

        //   this.user[this.userIndex].data.push(d);
        // this.tabUserData = {
        //   id: "2",
        //   workId: "",
        //   name: "",
        //   phone: "",
        //   type: "",
        //   num: "",
        //   typeImg: "",
        //   time: 30,
        //   times: "",
        //   other: "",
        //   status: "1",
        //   start: "",
        //   data: [],
        //   end: "",
        // };
      });
    },
    setyue(obj, type, fails) {
      console.log(
        obj.data.toString(),
        JSON.stringify(obj.data),
        obj.data,
        obj,
        this.user[this.userIndex],
        type,
        fails
      );

      // return false;
      let end = new Date(obj.end.split(" ")[0]).getTime();
      let start = new Date(obj.start.split(" ")[0]).getTime();
      let times = new Date(this.getDate()).getTime();
      if (start < times || end < times) {
        this.$message.error("过去的就让他过去吧！请预约未来或者今天的时间~");
        return false;
      }
      let href = type ? "/api/app/meiye/yue/edit" : "/api/app/meiye/yue/add";
      let status = type ? (fails ? fails : 0) : 0;
      let project_name = JSON.parse(JSON.stringify(obj.data));
      let project_name_data = [];

      if (typeof project_name == "object") {
        for (let i = 0; i < project_name.length; i++) {
          let names = project_name[i].name
            ? project_name[i].name
            : project_name[i];
          project_name_data.push(names);
        }
      }
      project_name_data = project_name_data.toString();

      // console.log(project_name_data,5555555)

      // project_name = project_name.replace(/,/g, "|");
      let d = {
        id: obj.id,
        name: obj.name,
        phone: obj.phone,
        project_name: project_name_data,
        staff_id: this.user[this.userIndex].id,
        time_begin: obj.start,
        time_end: obj.end,
        remark: obj.other,
        status: status,
      };
      console.log(d);
      this.$axios({
        href: href,
        data: d,
      }).then((res) => {
        // this.getyue(this.tabUserData.start, 0);
        console.log(res);
      });
    },
    getwork() {
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { status: 1 },
      }).then((res) => {
        let d = res.data;
        let len = d.length;
        for (let i = 0; i < len; i++) {
          d[i].data = [];
          d[i].number = d[i].workID;
          d[i].img = d[i].photo;
        }
        this.user = d;
        this.tabList = this.user;
        this.tabId = this.user[0].id;
        this.rounds(len);
        console.log(d);
        this.getyue();
      });

      this.$axios({
        href: "/api/app/meiye/projecttype/store",
        data: { status: 1 },
      }).then((res) => {
        this.headDate = res.data;
        this.$axios({
          href: "/api/app/meiye/project/store",
          data: { status: 1 },
        }).then((res) => {
          let d = this.headDate;
          let d1 = res.data;
          let len = d.length;
          let len1 = d1.length;

          for (let i = 0; i < len; i++) {
            d[i].list = [];
            for (let l = 0; l < len1; l++) {
              if (d1[l].category_id == d[i].id) {
                d[i].list.push(d1[l]);
              }
            }
          }
          this.headDate = d;
          console.log(d);
        });
      });
    },
    /* 获取工种 */
    getWorktype() {
      this.tabData = [{ id: 0, name: "全部" }];
      this.$axios({
        href: "/api/app/meiye/worktype/store",
      }).then((res) => {
        let d = res.data;
        let len = d.length;
        for (let i = 0; i < len; i++) {
          this.tabData.push(d[i]);
        }
        // console.log(res);
      });
    },
  },
  created() {
    this.times(9, 22.5);
    this.line("9:00", "22:30");
    this.tabSearchDate = this.tableData;
    // this.tabList = this.user;
    this.getwork();
    this.getWorktype();
  },
  mounted() {
    /* 时间和预约格子滚动同步 */
    // this.timeList();
    let t = document.querySelectorAll(".reservation-head-time")[0];
    let c = document.querySelectorAll(".reservation-cont-right")[0];
    t.onscroll = function() {
      c.scrollLeft = t.scrollLeft;
    };
    c.onscroll = function() {
      t.scrollLeft = c.scrollLeft;
    };
  },
  computed: {
    tabStatuss() {
      return this.$store.state.tabStatus;
    },
    navStatuss() {
      return this.$store.state.navStatus;
    },
    usershows: {
      get() {
        return this.$store.state.order.selectVip;
      },
      set() {
        return this.$store.state.order.selectVip;
      },
    },
    userInfo() {
      // this.tabUserData.name = this.$store.state.order.user.name;
      return this.$store.state.order.user;
    },
  },
  watch: {
    userInfo(d) {
      this.tabUserData.name = d.name;
      this.tabUserData.phone = d.phone;
      this.tableData = d.vip;

      this.tabSearchDate = this.tableData;
    },
  },
};
</script>

<style lang="scss" scoped>
.reservation {
  width: 100%;
  height: 100%;
  position: relative;

  &-head {
    width: 90.9%;
    height: 137px;
    position: fixed;
    top: 117px;
    // left: 196px;
    z-index: 99;
    padding-left: 13%;
    box-sizing: border-box;
    background: #fff;
    &-day {
      width: 100%;
      height: 85px;
      &-time {
        width: 100%;
        height: 100%;
        font-size: 18px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &-left {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          // background: red;
        }
        &-right {
          width: 30%;
          height: 100%;
          padding-right: 10%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          // background: blue;
        }

        &-picker-box {
          position: relative;
          width: 60%;
          padding-left: 10%;
          box-sizing: border-box;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            width: 120px;
            font-size: 20px;
            display: inline-block;
            cursor: pointer;
          }
          span:nth-child(2) {
            width: 300px;
            font-size: 18px;
          }
        }
        &-picker {
          position: absolute;
          bottom: 0px;
          left: 50%;
          margin-left: 0%;
          z-index: -1;
          opacity: 0;
        }

        &-search {
          width: 30%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          // background: blue;
          button {
            margin-right: 50px;
            color: #333;
            font-size: 20px;
          }
          // background: red;
        }
        &-search:first-child {
          width: 10%;
        }
        @media screen and (max-width: 1350px) {
          &-search {
            justify-content: center;
            // padding-left: 10%;
          }
        }
        @media screen and (max-width: 1034px) {
          &-picker-box {
            width: 50%;
          }
          &-search {
            width: 40%;
          }
        }
        &-tips {
          position: absolute;
          top: 0;
          left: 0;
          width: 15%;
          height: 100%;
          height: 100%;
          padding: 15px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          color: #28282d;
          font-family: PingFangSC-Semibold;
        }
      }
    }
    &-time {
      width: 100%;
      flex: 1;
      height: 52px;
      line-height: 42px;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      background: #fff;
      z-index: 1;
      span {
        display: inline-block;
        width: 135px;
        line-height: 42px;
        text-align: left;
        // padding-left: 10px;
        color: #28282d;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  &-cont {
    width: 100%;
    min-height: 100%;
    margin-top: 140px;
    display: flex;
    background: gainsboro;
    &-left {
      width: 15%;
      height: 100%;
      &-item {
        position: relative;
        display: flex;
        width: 100%;
        height: 107px;
        overflow-y: hidden;
        background: #f4f4f4;
        border-right: 0.5px solid rgba(220, 220, 220, 0.7);
        padding: 30px 10px 30px 20px;
        box-sizing: border-box;
        &-img {
          width: 45px;
          height: 45px;
          margin-right: 10px;
          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            box-shadow: 0 0 6px rgba(154, 154, 154, 0.5);
          }
        }
        &-info {
          max-width: 107px;
          overflow: hidden;
          &-name {
            line-height: 23px;
            font-size: 14px;
            color: #28282d;
            font-family: PingFangSC-Semibold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
          }
          &-number {
            line-height: 22px;
            font-size: 13px;
            color: #999;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
          }
        }
      }
    }
    &-right {
      width: 85%;
      height: 100%;
      overflow: auto;
      background: #fff;
      position: relative;
      display: flex;
      &-line {
        height: 100%;
        position: absolute;
        top: 0;
        left: 20px;
        bottom: 15px;
        width: 1px;
        background: #f44e4e;
        z-index: 88;
        transition: all 0.5s;
      }
      &-line::before {
        position: absolute;
        content: "";
        top: 0;
        left: -6px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #f44e4e;
      }
      &-line::after {
        position: absolute;
        content: "";
        bottom: 0;
        left: -6px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #f44e4e;
      }
      &-c {
        width: 135px;
        height: auto;
        &-box {
          width: auto;
          height: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          border-right: 1px dashed rgba(154, 154, 154, 0.5);
          &-item {
            width: 135px;
            height: 106px;
            color: #28282d;
            background: #fff;
            border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
            cursor: pointer;
            display: flex;
            position: relative;
            &-list {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              &-active,
              &-actives {
                width: 100%;
                height: 100%;
                background: rgba(71, 191, 124, 0.3);
                color: rgb(71, 191, 124);
                // display: flex;
                // align-items: center;
                // justify-content: center;
                p {
                  padding: 4px 2px;
                  margin: 0;
                  text-align: left;
                  width: 100%;
                  font-size: 14px;
                  height: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              &-active {
                border-left: 2px solid transparent;
                border-color: rgb(71, 191, 124);
              }
              &-active-img {
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
              }

              &-active-none {
                width: 100%;
                height: 100%;
                border-left: 2px solid transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgb(244, 78, 78);
                border-color: rgb(244, 78, 78);
                background: rgba(244, 78, 78, 0.3);
              }
              &-active-overdue {
                color: rgb(138, 138, 138);
                border-color: rgb(138, 138, 138);
                background: rgba(138, 138, 138, 0.3);
              }
            }
          }
        }
      }
    }
  }
}
.home-head-w {
  width: 96%;
  left: 62px;
  padding-left: 14.5%;
  transition: all 0.5s;
}
.home-head-h {
  // top: 47px;
}
.home-head-hs {
  width: 88.9%;
}
</style>

<style lang="scss" scoped>
.type-img {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 50px 30px;
  box-sizing: border-box;
  // background: red;
  div {
    flex: 1;
    text-align: center;
    cursor: pointer;
    img {
      width: 120px;
      height: 120px;
    }
  }
}
</style>

<style lang="scss" scoped>
/* 搜索 */

.reservation {
  &-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
    transition: opacity 0.5s;
    &-head {
      position: relative;
      padding: 35px 0 25px 0;
      background: #fff;
      z-index: 10;
      .input {
        width: 60%;
        margin: 0 auto;
      }
      button {
        position: absolute;
        top: 35px;
        right: 15px;
        width: 60px;
        height: 40px;
        font-size: 16px;
        color: #28282d;
      }
    }
    &-cont {
      position: absolute;
      top: 100px;
      left: 0;
      right: 0;
      width: 720px;
      height: 807px;
      bottom: 20px;
      margin: auto;
      min-width: 520px;
      background: #fff;
      border-radius: 6px;
      overflow-x: hidden;
      overflow-y: auto;
      &-box {
        &-head {
          padding: 0 15px;
          background: #f4f4f4;
          line-height: 40px;
          font-family: PingFangSC-Medium;
          font-size: 14px;
          text-align: left;
          color: #ff5e56;
        }
        &-list {
          display: flex;
          padding: 10px 15px;
          background: #fff;
          color: #28282d;
          cursor: pointer;
          &-time {
            font-size: 14px;
            line-height: 44px;
            width: 85px;
            min-width: 13px;
            border-right: 2px solid #47bf7c;
            padding-right: 10px;
            text-align: center;
            font-family: DINAlternate-Bold;
          }
          &-peopel {
            display: flex;
            flex: 1;
            margin-left: 10px;
            height: 44px;
            &-img {
              width: 24px;
              height: 24px;
              margin: 10px 0;
              img {
                width: 24px;
                height: 24px;
                overflow: hidden;
                border-radius: 50%;
              }
            }
            &-text {
              flex: 1;
              margin-left: 10px;
              padding: 4px 0;
              text-align: left;
              &-s {
                line-height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Medium;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              &-c {
                line-height: 20px;
                font-size: 12px;
                color: #8a8a8a;
              }
            }
          }
          &-other {
            flex: 1.6;
            margin-left: 10px;
            line-height: 44px;
            font-size: 14px;
            height: 44px;
            font-family: PingFangSC-Medium;
            color: #28282d;
            text-align: left;
            span:nth-child(2) {
              margin: 5px;
              color: #47bf7c;
            }
          }
        }
      }
      &-none {
        font-size: 13px;
        font-family: PingFangSC-Medium;
        color: #8a8a8a;
        text-align: center;
        line-height: 120px;
      }
    }
  }
  &-search-active {
    z-index: 99;
    opacity: 1;
  }
}
</style>

<style lang="scss" scoped>
.alerts {
  width: 100%;
  // height: 100%;
  &-box {
    width: 100%;
    height: 100vh;
    display: flex;
    &-left {
      flex: 1;
      // background: red;
      overflow: hidden;
      &-head {
        position: relative;
        padding: 35px 52px 10px 52px;
        line-height: 40px;
        height: 85px;
        background: #fff;
        z-index: 10;
        box-sizing: border-box;
        &-btn {
          position: absolute;
          top: 35px;
          left: 12px;
          cursor: pointer;
          width: 40px;
          height: 40px;
          background: rgba(0, 0, 0, 0);
          background-image: url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_NavBack.png);
          background-position: left center;
          background-size: 28px;
          background-repeat: no-repeat;
          border: none;
          outline: none;
        }
        &-cont {
          span {
            cursor: pointer;
            position: relative;
            font-size: 15px;
            font-family: PingFangSC-Semibold;
            color: #8a8a8a;
            transition: all 0.3s;
            padding: 0 10px;
            line-height: 40px;
            height: 40px;
          }
          &-span {
            color: #28282d !important;
          }
          &-span::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            height: 2px;
            width: 20px;
            margin-left: -10px;
            background: #28282d;
          }
        }
      }
      &-cont {
        &-head-box {
          position: relative;
          background: #fff;
          padding: 0 15px 15px 15px;
          z-index: 10;
        }
        &-head {
          position: relative;
          height: 44px;
          line-height: 44px;
          padding: 0 40px 0 10px;
          border-radius: 5px;
          background: #f8f8f8;
          overflow: hidden;
          button {
            border: none;
            outline: none;
            box-sizing: border-box;
            cursor: pointer;
            padding: 0;
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 44px;
            background: transparent
              url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_ArrowUp.png)
              center / 28px no-repeat;
            -webkit-transition: -webkit-transform 0.5s;
            transform: rotate(180deg);
          }
          &-btn {
            transform: rotate(0deg) !important;
          }
          span {
            cursor: pointer;
            position: relative;
            display: inline-block;
            text-align: center;
            padding: 0 18px;
            height: 44px;
            color: #5a5a5a;
          }
          &-span {
            color: #47bf7c !important;
          }
        }
        &-box {
          position: relative;
          background: #fff;
          overflow-x: hidden;
          overflow-y: auto;
          height: 807px;
          padding: 5px 0 25px 18px;
          &-item:hover {
            border: 1px solid #409eff;
            box-shadow: 0 2px 2px 1px #ddd;
          }
          &-item {
            position: relative;
            cursor: pointer;
            float: left;
            width: 180px;
            height: 240px;
            padding: 10px;
            box-sizing: border-box;
            // background: #f8f8f8;
            border-radius: 6px;
            background: #ffffff;
            border: 1px solid #f2f2f2;
            text-align: center;
            margin: 0 15px 15px 0;
            // box-shadow: 0 2px 2px 1px #ddd;
            transition: all 0.5s;
            &-img {
              width: 160px;
              height: 160px;
              img {
                width: 100%;
                border-radius: 5px;
                height: 100%;
              }
            }
            &-title {
              height: 71px;
              font-size: 15px;
              color: #28282d;
              line-height: 20px;
              padding: 23px 5px 0 5px;
              overflow: hidden;
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
            }
            &-num {
              height: 34px;
              width: 100%;
              line-height: 34px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // background: #f4f4f4;
              color: #000000;
              font-size: 14px;
              text-align: left;
              font-family: DINAlternate-Bold;
              border-bottom-left-radius: 6px;
              border-bottom-right-radius: 6px;
            }
          }
          &-items {
            float: left;
            width: 140px;
            height: 76px;
            border-radius: 4px;
            background: #fff;
            box-shadow: 0 0 2px 0 rgba(220, 220, 220, 0.4);
            text-align: center;
            margin-right: 10px;
            margin-bottom: 10px;
            font-size: 15px;
            color: #28282d;
            line-height: 20px;
            padding: 28px 5px 0 5px;
            box-sizing: border-box;
            cursor: pointer;
            transition: all 0.5s;
          }
          &-items-active {
            background: #47bf7c;
            color: #fff;
            font-family: PingFangSC-Medium;
          }
          &-vip {
            height: 770px;
            padding: 10px 18px 25px 5px;
            overflow-x: hidden;
            overflow-y: auto;

            &-list {
              position: relative;
              display: flex;
              padding: 16px 30px 16px 15px;
              line-height: 20px;
              font-size: 14px;
              color: #28282d;
              background: #fff
                url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Next.png)
                right center / 28px no-repeat;
              cursor: pointer;
              box-shadow: 0 0 3px 1px rgba(220, 220, 220, 0.4);
              margin-bottom: 15px;
              border-radius: 5px;

              div {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              div:nth-of-type(1) {
                flex: 2;
                text-align: left;
              }
            }

            button {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 120px;
              background: transparent;
              border: none;
              outline: none;
              box-sizing: border-box;
              cursor: pointer;
            }
          }
        }
      }
      &-search {
        background: #f4f4f4;
        height: 40px;
        padding: 9px 12px;
        &-btn {
          position: relative;
          padding: 15px 0 10px 35px;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_N.png)
            0px center / 28px no-repeat;
          cursor: pointer;
          box-sizing: border-box;
          color: #5a5a5a;
          border: none;
          outline: none;
          &-active {
            background: transparent
              url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_S.png)
              0px center / 28px no-repeat;
          }
        }
      }
    }
    &-right {
      position: relative;
      z-index: 10;
      width: 400px;
      min-width: 400px;
      padding: 0 10px;
      box-sizing: border-box;
      box-shadow: 0 0 2px 0 #ddd;
      &-vip {
        position: relative;
        display: flex;
        background: #d6c361
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_VIP_Small.png)
          90% center / 82px no-repeat;
        height: 50px;
        border-radius: 6px 6px 0 0;
        padding: 7px 10px;
        cursor: pointer;
        // margin: 0 15px 10px 15px;
        box-sizing: border-box;
        &-img {
          width: 36px;
          height: 36px;
          border: 0.5px solid #fff;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 12px;
          img {
            width: 36px;
            height: 36px;
            background: #fff;
            border-radius: 50%;
          }
        }
        &-text {
          display: flex;
          flex: 1;
          line-height: 36px;
          color: #604e2a;
          font-size: 14px;
          text-align: left;
          &-name {
            line-height: 36px;
            margin-right: 10px;
            font-family: PingFangSC-Medium;
          }
          &-other {
            line-height: 36px;
            opacity: 0.7;
          }
        }
        button {
          width: 48px;
          height: 36px;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_ResetCard.png)
            center / 28px no-repeat;
          cursor: pointer;
          border: none;
          outline: none;
          box-sizing: border-box;
          padding: 0;
        }
      }
      &-head {
        width: 100%;
        display: flex;
        padding: 35px 12px 10px 12px;
        font-family: PingFangSC-Semibold;
        color: #28282d;
        height: 85px;
        box-sizing: border-box;
        // background: blue;
        span {
          display: block;
          height: 32px;
          line-height: 32px;
          font-family: PingFangSC-Semibold;
          font-size: 20px;
          color: #28282d;
          padding-right: 20px;
          margin: 4px 0;
          border-right: 0.5px solid #ddd;
        }
        &-type {
          display: flex;
          height: 34px;
          border-radius: 6px;
          overflow: hidden;
          background: #f4f4f4;
          padding: 0;
          margin: 4px 0 4px 12px;
          &-btn {
            flex: 1;
            width: 52px;
            height: 34px;
            line-height: 34px;
            background: #f4f4f4;
            color: #8a8a8a;
            font-size: 15px;
            padding: 0;
            border: none;
            outline: none;
            box-sizing: border-box;
            cursor: pointer;
            transition: all 0.5s;
          }
          &-btn-active {
            color: #fff;
            background: #28282d;
          }
        }
      }

      &-list {
        position: relative;
        margin-bottom: 15px;
        background: #f4f4f4;
        border-radius: 6px;
        padding: 0 15px;
        &-item {
          display: flex;
          align-items: center;
          line-height: 24px;
          padding: 15px 0;
          border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
          span {
            width: 70px;
            font-size: 15px;
            font-family: PingFangSC-Medium;
            color: #28282d;
          }
          span:last-child {
            flex: 1;
            padding-right: 15px;
            text-align: right;
            color: #28282d;
            font-size: 14px;
            padding-right: 20px;
            background: transparent
              url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Right.png)
              260px center / 28px no-repeat;
          }
        }
        &-item:last-child {
          border-bottom: none;
        }
        &-items {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      &-title {
        margin-top: 10px;
        line-height: 36px;
        padding-left: 15px;
        font-size: 15px;
        font-family: PingFangSC-Medium;
        color: #28282d;
        text-align: left;
      }
      &-lists {
        position: relative;
        display: flex;
        line-height: 20px;
        padding: 17px 10px 17px 46px;
        font-size: 14px;
        color: #5a5a5a;
        background: #f4f4f4;
        border-radius: 6px;
        margin-bottom: 10px;
        cursor: pointer;
        span {
          flex: 1;
          text-align: left;
        }
        button {
          position: absolute;
          width: 44px;
          height: auto;
          left: 0;
          top: 0;
          bottom: 0;
          cursor: pointer;
          border: none;
          outline: none;
          box-sizing: border-box;
          padding: 0;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Del_Gray.png)
            center / 28px no-repeat;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.tabData-box {
  width: 100%;
  height: 575px;
  position: relative;
  z-index: 10;
  margin-top: -10px;
  background: #f4f4f4;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 0 20px 15px;
  &-list {
    display: flex;
    height: 44px;
    background: #f4f4f4
      url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Check_N.png) 95%
      center / 28px no-repeat;
    padding: 12px 50px 12px 0;
    // box-sizing: border-box;
    cursor: pointer;
    &-img {
      margin-right: 15px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    &-name {
      flex: 1;
      font-family: PingFangSC-Semibold;
      font-size: 15px;
      color: #28282d;
      line-height: 20px;
      padding: 10px 0;
      text-align: left;
    }
    &-number {
      flex: 1;
      color: #8a8a8a;
      padding: 10px 0;
      margin-left: 10px;
      text-align: left;
    }
  }
  &-list-active {
    background: #f4f4f4
      url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Check_S.png) 95%
      center / 28px no-repeat;
  }
}
.time-alert {
  display: inline-block;
  width: 284px;
  height: 504px;
  padding: 12px 42px;
  overflow-y: auto;
  &-list {
    line-height: 44px;
    height: 44px;
    text-align: left;
    font-size: 18px;
    color: #28282d;
    padding-left: 50px;
    cursor: pointer;
    transition: all 0.5;
    p {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }
    span:last-child {
      margin-left: 12px;
      font-size: 14px;
      color: #ccc;
    }
  }
  &-list-active {
    border-top: 0.5px solid rgba(154, 154, 154, 0.5);
    border-bottom: 0.5px solid rgba(154, 154, 154, 0.5);
    padding-left: 0px;
    span:first-child {
      padding-left: 50px;
      font-size: 20px;
      border-left: 2px solid #47bf7c;
      color: #47bf7c;
      font-family: DINAlternate-Bold;
      box-sizing: border-box;
    }
  }
}
</style>

<style lang="scss" scoped>
.edit-box {
  width: 100%;
  height: auto;
  position: relative;
  &-cont {
    width: 100%;
    height: 100%;
    max-height: 646px;

    border-top: 0.5px solid rgba(220, 220, 220, 0.7);
    background: #fff;
    padding: 0 20px 25px 20px;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    button {
      // background: transparent;
      border: none;
      outline: none;
      box-sizing: border-box;
      cursor: pointer;
    }
    &-close {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 40px;
      height: 40px;
      background: transparent
        url(https://static.bokao2o.com/wisdomCashier/images/Icon_X_Gray.png)
        center / 28px 28px no-repeat;
    }
    &-text {
      position: absolute;
      font-size: 15px;
      color: #28282d;
      text-align: center;
      padding: 0;
      line-height: 40px;
      width: 55px;
      height: 40px;
      top: 10px;
      right: 10px;
      background: transparent;
    }

    &-info {
      border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
      padding: 10px 20px;
      &-list {
        display: flex;
        line-height: 30px;
        padding: 12px 0;
        color: #8a8a8a;
        &-item {
          font-size: 15px;
          color: #8a8a8a;
          min-width: 70px;
        }
        &-item:last-child {
          flex: 1;
          text-align: right;
          font-size: 15px;
          font-family: PingFangSC-Medium;
          color: #28282d;
        }
        &-items {
          padding-right: 30px;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Right.png)
            right center / 28px no-repeat;
        }
      }
    }
    &-info:last-child {
      border-bottom: none;
    }
    &-infos {
      background: #f4f4f4;
      border-radius: 6px;
      border-bottom: none;
      padding: 0 10px 0 20px;
      margin-top: 15px;
      .edit-box-cont-info-list {
        cursor: pointer;
      }
    }
  }
  .dialog-footer1 {
    width: 100%;
    display: flex;
    button {
      width: 100%;
    }
  }
}
</style>

<style moudle>
.reservation .el-input__inner {
  border: none;
  outline: none;
}
.reservation .el-select-dropdown .el-popper {
  z-index: 3005 !important;
}
.reservation-search .el-input-group__append {
  cursor: pointer;
  border-color: #28282d;
  background: #28282d;
  padding: 0;
  box-sizing: border-box;
  /* font-size: 15px; */
  /* overflow: hidden; */
  color: #fff;
}
.reservation-search .el-input-group__append div {
  width: 58px;
  height: 38px;
  /* position: absolute; */
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: red; */
}

.reservation-search-head .el-input__inner:focus {
  border-color: #28282d;
}

.alerts .el-dialog {
  position: relative;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0 !important;
}
.alerts .el-dialog__body,
.alerts .el-dialog__header {
  padding: 0 !important;
}
.alerts-box-left-search .el-input__inner {
  border: none;
}
.alerts-box-left-search .el-input__inner:focus {
  border-color: rgba(0, 0, 0, 0);
}
.el-table__row {
  cursor: pointer;
}

.edit-box .el-input__inner,
.edit-box .el-textarea__inner,
.edit-box .el-textarea .el-input__count {
  background-color: transparent;
}

.alerts-box-right-list-item .el-input__inner,
.alerts-box-right-list-item .el-textarea__inner {
  border: none;
  background-color: #f4f4f4;
}
.alerts-box-right-list-item .el-input,
.alerts-box-right-list-item .el-textarea {
  width: 80%;
}
.alerts-box-right-list-item .el-textarea .el-input__count {
  background: rgba(255, 255, 255, 0);
}
.alerts-box-right-list-items .el-input-number__increase,
.alerts-box-right-list-items .el-input-number__decrease,
.edit-box .el-input-number__increase,
.edit-box .el-input-number__decrease {
  border: none;
  font-size: 25px;
  background: rgba(255, 255, 255, 0);
}
.alerts-box-right-list-items .el-input-number .el-input {
  display: inline-block;
}
.wh_content_all {
  background: #fff !important;
  display: inline-block;
  /* width: 576px; */
  width: 110% !important;
  padding: 0 8px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(40, 40, 45, 0.5);
  /* height: 550px; */
}
.wh_item_date,
.wh_content_li,
.wh_content_item,
.wh_top_changge li {
  color: #000 !important;
}
.wh_jiantou2 {
  width: 8px !important;
  height: 8px !important;
  border-top: 2px solid #000 !important;
  border-right: 2px solid #000 !important;
}
.wh_jiantou1 {
  width: 8px !important;
  height: 8px !important;
  border-top: 2px solid #000 !important;
  border-left: 2px solid #000 !important;
}
.wh_content_item .wh_isToday {
  background: #81bfff !important;
  color: #fff !important;
}
.wh_container {
  width: 88% !important;
  max-width: 90% !important;
  margin: 0 !important;
}
.wh_content_item {
  height: 80px !important;
}
.wh_content_item .wh_chose_day {
  transition: all 0.5s;
  color: #fff !important;
}
.wh_content_item > .wh_isMark {
  background: #278bf3 !important;
  color: #fff !important;
}
.wh_item_date {
  transition: all 0.5s;
}
.wh_item_date:hover {
  background: #ecf5ff !important;
  border-radius: 50%;
  color: #000 !important;
}
.wh_chose_day {
  background: #409eff !important;
}
.pointer {
  cursor: pointer;
}
.reservation .el-tabs__active-bar {
  background-color: #28282d;
}
.reservation .el-tabs__item.is-active {
  color: #28282d;
}
</style>
