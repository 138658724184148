<template>
  <div
    class="select-vip"
    :class="shows ? 'select-vip-active' : ''"
    @click.self="hide"
  >
    <div class="select-vip-cont" v-show="shows">
      <div class="select-vip-cont-texts" v-show="!searchStatus">
        <button class="select-vip-cont-texts-face"></button>
        <div class="select-vip-cont-texts-title">您想要查找哪位会员?</div>
        <div class="select-vip-cont-texts-info">
          可对 会员详情、照片、充值等进行操作...
        </div>
        <div class="select-vip-cont-texts-input">
          <el-select v-model="value" placeholder="请选择" style="z-index: 3005">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <el-input
            style="width:250px;border-left:1px solid #d4d4d5;"
            v-model="input"
            placeholder="请输入内容"
          ></el-input>
          <button
            class="select-vip-cont-texts-input-button"
            @click="searchShow"
            :class="
              input.length ? 'select-vip-cont-texts-input-button-active' : ''
            "
          >
            查询
          </button>
        </div>
        <template v-if="recentSearch.length > 0">
          <div class="select-vip-cont-texts-input-recent-search-title">最近查询</div>
          <div class="select-vip-cont-texts-input-recent-search">
            <div class="select-vip-cont-texts-input-recent-search-item" v-for="(item, index) in recentSearch" :key="index" @click="handleClickRecentSearch(item)">{{item}}</div>
          </div>
        </template>
      </div>

      <div
        class="select-vip-cont-list"
        :class="searchStatus ? 'select-vip-cont-list-active' : ''"
      >
        <i class="el-icon-circle-close" @click="clearSearch"></i>
        <div class="select-vip-cont-texts-input select-vip-cont-list-input">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <el-input
            style="width:250px;border-left:1px solid #d4d4d5;"
            v-model="input"
            clearable
            @clear="clearSearch"
            placeholder="请输入内容"
          ></el-input>
          <button
            class="select-vip-cont-texts-input-button"
            @click="searchShow"
            :class="
              input.length ? 'select-vip-cont-texts-input-button-active' : ''
            "
          >
            查询
          </button>
        </div>
        <div class="select-vip-cont-list-head">
          <div>卡号</div>
          <div>姓名</div>
          <div>手机号</div>
          <div>卡类型</div>
          <div>储值余额</div>
          <div>归属门店</div>
        </div>
        <div class="select-vip-cont-list-item">
          <div
            class="select-vip-cont-list-item-list"
            v-for="(item, index) in searchData"
            :key="index"
            @click="userActive(index)"
          >
            <div class="select-vip-cont-list-item-list-viewimg">
              <img
                :src="item.head_picture"
                :alt="item.name"
                v-if="item.head_picture"
              />
              <img src="../../assets/none.png" :alt="item.name" v-else />
            </div>
            <div class="select-vip-cont-list-item-list-view">
              {{ item.card }}
            </div>
            <div class="select-vip-cont-list-item-list-view">
              {{ item.name }}
            </div>
            <div class="select-vip-cont-list-item-list-view">
              {{ item.phone }}
            </div>
            <div class="select-vip-cont-list-item-list-view">
              {{ item.membertype ? item.membertype.name : "--" }}
            </div>
            <div class="select-vip-cont-list-item-list-view">
              {{ item.amount }}
            </div>
            <div class="select-vip-cont-list-item-list-view">
              {{ item.superior || "平台" }}
            </div>
          </div>
        </div>

        <div class="center">
          <el-pagination
            v-if="$pageStatus(total)[0]"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="$pageStatus(total)[1]"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectVips",
  data() {
    return {
      options: [
        {
          value: "0",
          label: "号码",
        },
        {
          value: "1",
          label: "姓名",
        },
      ],
      value: "0", //搜索类型
      input: "", //搜索关键字
      recentSearch: [], // 最近搜索（最多5条）
      searchStatus: false, //显示搜索状态
      user: [], //会员数据
      searchData: [], //搜索会员数据
      page: 1,
      total: 1,
      perPage: 15,
    };
  },
  methods: {
    hide() {
      this.$store.commit("selectVipShows", false);
    },
    getUser(obj) {
      let _this = this;
      this.$axios({
        href: "/api/app/meiye/member/store",
        data: {
          keyword: obj,
          name: obj,
          page: this.page,
          perPage: this.total,
        },
      }).then((res) => {
        console.log(res.data.items);
        _this.user = res.data.items;
        _this.searchData = res.data.items;
        _this.total = res.data.total;
        if (!_this.user.length) {
          this.$message({
            message: "请更换搜索关键字查询~",
            type: "warning",
          });
        }
      });
    },
    searchShow() {
      // this.getUser(this.input);
      // console.log(11111111);
      if (!this.input) {
        this.$message({
          message: "请输入查询条件",
          type: "warning",
        });
        return false;
      } else {
        // 存储最近5条搜索数据
        let thisRecentSearch = this.recentSearch;
        let thisRecentSearchLen = thisRecentSearch.length;
        let concatArr = [];
        if (thisRecentSearchLen > 0) {
          for (let i = 0; i < thisRecentSearchLen; i++) {
            // 如果关键字已经在最近搜索列表里，则不再添加进去
            if (thisRecentSearch[i] === this.input) {
              break;
            } else {
              if (i === thisRecentSearchLen - 1) {
                concatArr = [this.input];
              }
            }
          }
        } else {
          concatArr = [this.input];
        }
        let recentSearch = concatArr.concat(this.recentSearch);
        if (recentSearch.length > 5) {
          this.recentSearch = recentSearch.splice(0, 5);
        } else {
          this.recentSearch = recentSearch;
        }
        window.localStorage.setItem('recentSearch', JSON.stringify(this.recentSearch));
      }
      this.getUser(this.input);
      this.searchData = [];
      let data = this.user;
      let len = data.length;

      for (let i = 0; i < len; i++) {
        if (this.value == "0" && data[i].phone.includes(this.input)) {
          this.searchData.push(data[i]);
        }
        if (this.value == "1" && data[i].name.includes(this.input)) {
          this.searchData.push(data[i]);
        }
        // if (i == len - 1) {
        //   if (!this.searchData.length) {
        //     this.$message({
        //       message: "请更换搜索关键字查询1~",
        //       type: "warning",
        //     });
        //   }
        // }
      }
      this.searchStatus = true;
    },
    /**
     * 点击最近搜索选项，搜索会员
     */
    handleClickRecentSearch(params) {
      this.input = params;
      this.getUser(params);
      this.searchData = [];
      let data = this.user;
      let len = data.length;
      for (let i = 0; i < len; i++) {
        if (this.value == "0" && data[i].phone.includes(this.input)) {
          this.searchData.push(data[i]);
        }
        if (this.value == "1" && data[i].name.includes(this.input)) {
          this.searchData.push(data[i]);
        }
      }
      this.searchStatus = true;
    },
    clearSearch() {
      this.input = "";
      this.searchStatus = !this.searchStatus;
    },
    /* 选中的会员 */
    userActive(index) {
      let _this = this;
      this.searchData[index].vip = [];

      this.$axios({
        href: "/api/app/meiye/member/index",
        data: { id: this.searchData[index].id },
      }).then((res) => {
        console.log(res, "选中的会员");
        for (let i = 0; i < res.data.card.length; i++) {
          for (let k = 0; k < res.data.card[i].treats.length; k++) {
            res.data.card[i].treats[k].type = res.data.card[i].name;
            res.data.card[i].treats[k].vip = true;
            res.data.card[i].treats[k].treat_id = res.data.card[i].treats[k].id;
            if (res.data.card[i].treats[k].balance_count - 0) {
              _this.searchData[index].vip.push(res.data.card[i].treats[k]);
            }
          }
        }
        for (let l = 0; l < res.data.treat.length; l++) {
          res.data.treat[l].vip = true;
          res.data.treat[l].treat_id = res.data.treat[l].id;
          if (res.data.treat[l].balance_count - 0) {
            _this.searchData[index].vip.push(res.data.treat[l]);
          }
        }
        _this.searchData[index].card = res.data.card;
      });
      // console.log(this.searchData[index]);

      if (this.searchData[index].membertype) {
        this.searchData[index].membertype.product_disc =
          this.searchData[index].membertype.product_disc / 100;
        this.searchData[index].membertype.project_disc =
          this.searchData[index].membertype.project_disc / 100;
      } else {
        this.searchData[index].membertype = {id:0,name:"大众会员"};
        this.searchData[index].membertype.product_disc = 1;
        this.searchData[index].membertype.project_disc = 1;
      }
      console.log("selectVip:", this.searchData[index]);
      this.$store.commit("setUser", this.searchData[index]);
      this.$store.commit("selectVipShows", false);
      this.input = "";
      this.searchStatus = false;
    },
    sizeChange(page) {
      this.page = page;
      this.getUser(this.input);
    },
    handleSizeChange(val) {
      this.page = val;
      this.getUser(this.input);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getUser(this.input);
    },
  },
  beforeMount() {
    let recentSearch = window.localStorage.getItem('recentSearch');
    if (recentSearch && recentSearch.length > 0) {
      this.recentSearch = eval('(' + recentSearch + ')');
      console.log('this.recentSearch', this.recentSearch);
    }
  },
  computed: {
    shows: {
      get() {
        return this.$store.state.order.selectVip;
      },
      set() {
        return this.$store.state.order.selectVip;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.select-vip {
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transition: opacity 0.5s;
  cursor: pointer;
  &-cont {
    top: 10%;
    width: 800px;
    max-height: 620px;
    border-radius: 6px;
    position: absolute;
    border-radius: 6px;
    left: 0;
    right: 0;
    margin: auto;
    min-height: 200px;
    box-shadow: rgb(51, 51, 51) 0px 0px 20px 0px;
    height: 620px;
    border-radius: 6px;
    background: #f8f8f8
      url(https://static.bokao2o.com/wisdomDesk/images/Def_SearchCard_BcImg.png)
      top / 100% no-repeat;
    overflow: hidden;
    &-texts {
      position: relative;
      width: 400px;
      height: 170px;
      left: 0;
      right: 0;
      margin: 180px auto 0 auto;
      -webkit-transition: -webkit-transform 0.7s, background 0.7s,
        font-size 0.7s;
      &-face {
        position: absolute;
        width: 44px;
        height: 44px;
        background: transparent
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_AuthFace.png)
          center / 28px no-repeat;
        top: 95px;
        right: -100px;
        cursor: pointer;
        border: none;
        outline: none;
        box-sizing: border-box;
      }
      &-title {
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #28282d;
        line-height: 40px;
        text-align: left;
      }
      &-info {
        margin-top: 5px;
        line-height: 20px;
        color: #5a5a5a;
        font-size: 15px;
        margin-bottom: 30px;
        text-align: left;
      }
      &-input {
        margin-top: 10px;
        display: flex;
        background: #fff;
        border-radius: 6px;
        overflow: hidden;
        height: 44px;
        &-button {
          width: 70px;
          height: 44px;
          padding: 0;
          line-height: 44px;
          font-family: PingFangSC-Semibold;
          font-size: 16px;
          color: #fff;
          background: #d4d4d5;
          cursor: pointer;
          border: none;
          outline: none;
          box-sizing: border-box;
          transition: all 0.5s;
        }
        &-button-active {
          background: #28282d;
        }
        &-recent-search-title {
          padding-left: 10px;
          font-size: 14px;
          color: #000000;
          line-height: 42px;
          text-align: left;
          box-sizing: border-box;
        }
        &-recent-search {
          display: flex;
          flex-wrap: wrap;
          &-item {
            margin: 0 10px 10px;
            padding: 0 12px;
            font-size: 12px;
            color: #333333;
            line-height: 32px;
            border-radius: 16px;
            background-color: #EEEEEE;
          }
        }
      }
    }
    &-list {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      top: 100%;
      padding-top: 85px;
      //   background: red;
      transition: all 0.5s;
      .el-icon-circle-close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 25px;
      }
      &-head {
        width: 680px;
        height: 55px;
        display: flex;
        background: #fff;
        padding-left: 110px;
        padding-right: 10px;
        div {
          flex: 1;
          text-align: center;
          line-height: 55px;
          font-size: 14px;
          color: #8a8a8a;
        }
      }
      &-item {
        width: 800px;
        height: 448px;
        text-align: center;
        background: #fff;
        overflow-x: hidden;
        overflow-y: auto;
        &-list:hover {
          cursor: pointer;
          background: #f4f4f4;
        }
        &-list {
          position: relative;
          display: flex;
          padding-left: 110px;
          box-sizing: border-box;
          margin-bottom: 25px;
          padding-right: 10px;
          background: #fff;
          -webkit-transition: -webkit-transform 0.3s, background 0.3s;
          div {
            flex: 1;
            padding: 12px 5px;
            line-height: 20px;
            font-size: 14px;
            color: #28282d;
            text-align: center;
          }
          &-view {
            transition: all 0.5s;
            text-align: center;
          }

          &-viewimg {
            position: absolute;
            left: 38px;
            top: 0;
            bottom: 0;
            width: 68px;
            height: 44px;
            padding: 0;
            margin: auto;
            img {
              width: 68px;
              height: 44px;
              border-radius: 5px;
            }
          }
        }
      }
      &-input {
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        margin: auto;
        width: 400px;
        background: #fff;
        height: 44px;
        display: flex;
        border-radius: 6px;
        overflow: hidden;
      }
    }
    &-list-active {
      top: 0;
    }
  }
}

.select-vip-active {
  position: fixed;
  z-index: 3000;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  //   transition: all 0.5s;
}
</style>

<style moudle>
.select-vip .el-select {
  width: 88px;
  height: 44px;
}
.select-vip .el-input__inner {
  height: 45px;
  background: #ffffff !important;
  color: #000 !important;
}
.select-vip .el-input__inner::placeholder {
  color: #000 !important;
}

.select-vip .el-select .el-input .el-select__caret {
  color: #000 !important;
}
.select-vip .el-pagination {
  background: #fff;
}
.select-vip .el-input__inner {
  border: none;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
