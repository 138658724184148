import { render, staticRenderFns } from "./selectVip.vue?vue&type=template&id=e906665e&scoped=true&"
import script from "./selectVip.vue?vue&type=script&lang=js&"
export * from "./selectVip.vue?vue&type=script&lang=js&"
import style0 from "./selectVip.vue?vue&type=style&index=0&id=e906665e&lang=scss&scoped=true&"
import style1 from "./selectVip.vue?vue&type=style&index=1&moudle=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e906665e",
  null
  
)

export default component.exports